import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, zip } from 'rxjs';
import { Employee } from '../domain/employee';
import { EmployeeList } from '../domain/employee-list';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Certificate } from '../domain/certificate';
import { Passport } from '../domain/passport';
import { Visa } from '../domain/visa';
import { VisaTypes } from '../domain/visa-types';
import { SkillsAndProfessions } from '../domain/skillsAndProfessions';
import { Skill } from '../domain/skill';
import { SearchEmployeeData } from '../domain/search-employee-data';
import { EmployeeFile } from '../domain/employee-file';
import { EmploymentHistory } from '../domain/employment-history';
import { ContractForm } from '../domain/contractForm';
import { CompensationTypeService } from './compensation-type.service';
import { Contract } from '../domain/contract';
import { CompensationType } from '../domain/compensation-type';

interface Contracts {
  current: Contract;
  historical: Contract[];
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private employeesCache: Map<string, Observable<Employee>> = new Map();
  private employeesCacheRefreshers: Map<string, BehaviorSubject<void>> = new Map();

  private employeeContractsCache: Map<string, Observable<Contracts>> = new Map();
  private employeeContractsCacheRefreshers: Map<string, BehaviorSubject<void>> = new Map();

  readonly visaTypes$ = this.httpClient.get<VisaTypes[]>(`${ environment.api }/visa-type`).pipe(
    shareReplay(1),
  );

  constructor(private readonly httpClient: HttpClient, private readonly compensationTypeService: CompensationTypeService) { }

  static prepareSkillsAndProfessionsData(skillsAndProfessions: Partial<Employee>): SkillsAndProfessions {
    const value = Object({});
    value.professionId = skillsAndProfessions.profession.id;
    value.secondaryProfessionId = skillsAndProfessions.secondaryProfession ? skillsAndProfessions.secondaryProfession.id : null;
    value.skillIds = skillsAndProfessions.skills.map((skill: Skill) => skill.id);
    return value;
  }

  getEmployeeList(data: Partial<SearchEmployeeData>): Observable<EmployeeList> {
    const params = EmployeeService.mapToParams(data);

    return this.httpClient.get<EmployeeList>(`${ environment.api }/employee`, { params });
  }

  static mapToParams(employeeFilters: Partial<SearchEmployeeData>): HttpParams {
    let params = new HttpParams()
      .set('limit', employeeFilters.pageSize.toString())
      .set('page', (employeeFilters.pageIndex + 1).toString());

    if (employeeFilters.skillIds && employeeFilters.skillIds.length > 0) {
      const skillIdsString = employeeFilters.skillIds.join(',');
      params = params.append('skillIds', skillIdsString);
    }

    if (employeeFilters.certificateIds && employeeFilters.certificateIds.length > 0) {
      const certificateIdsString = employeeFilters.certificateIds.join(',');
      params = params.append('certificateIds', certificateIdsString);
    }

    if (employeeFilters.statuses && employeeFilters.statuses.length > 0) {
      const statusString = employeeFilters.statuses.join(',');
      params = params.append('statuses', statusString);
    }

    params = employeeFilters.professionId ? params.append('professionId', employeeFilters.professionId.toString()) : params;
    params = employeeFilters.visaTypeId ? params.append('visaTypeId', employeeFilters.visaTypeId.toString()) : params;
    params = employeeFilters.search ? params.append('search', employeeFilters.search) : params;
    params = employeeFilters.employeeTypeId ? params.append('employeeTypeId', employeeFilters.employeeTypeId.toString()) : params;
    params = employeeFilters.nationalityCode ? params.append('nationalityCode', employeeFilters.nationalityCode) : params;
    params = employeeFilters.phoneNumber ? params.append('phoneNumber', employeeFilters.phoneNumber) : params;
    if (employeeFilters.direction && employeeFilters.active) {
      params = params.append('sort', employeeFilters.active);
      params = params.append('order', employeeFilters.direction);
    }
    if (employeeFilters.availableFrom && employeeFilters.availableTo) {
      params = params.append('availableFrom', employeeFilters.availableFrom);
      params = params.append('availableTo', employeeFilters.availableTo);
    }

    if (employeeFilters.ratings) {
      const ratingFilters = employeeFilters.ratings.filter((rating) => rating?.value);

      for (const rating of ratingFilters) {
        params = params.append(`ratings[${rating.categoryId}]`, rating.value.toString());
      }
    }

    return params;
  }



  createEmployee(employee: Partial<Employee>): Observable<Employee> {
    const value = Object({ ...employee });
    delete value.languages;
    value.languageIds = employee.languages ? employee.languages.map(language => language.id) : [];

    return this.httpClient.post<Employee>(`${ environment.api }/employee`, value);
  }

  getEmployee(employeeId: string): Observable<Employee> {
    if (!this.employeesCache.has(employeeId)) {
      this.employeesCacheRefreshers.set(employeeId, new BehaviorSubject<void>(undefined));

      this.employeesCache.set(
        employeeId,
        this.employeesCacheRefreshers
          .get(employeeId)
          .pipe(
            switchMap(() => this.httpClient.get<Employee>(`${ environment.api }/employee/${ employeeId }`)),
            shareReplay(1),
          ),
      );
    }

    return this.employeesCache.get(employeeId);
  }

  refreshEmployee(employeeId: string): void {
    this.employeesCacheRefreshers.get(employeeId)?.next();
  }

  editEmployee(employee: Partial<Employee>): Observable<Employee> {
    const value = Object({ ...employee });
    delete value.languages;
    value.languageIds = employee.languages ? employee.languages.map(language => language.id) : [];

    return this.httpClient.put<Employee>(`${ environment.api }/employee/${ employee.id }`, value);
  }

  getEmploymentHistory(employeeId: string): Observable<EmploymentHistory[]> {
    return this.httpClient.get<EmploymentHistory[]>(`${ environment.api }/employment-history/${ employeeId }`);
  }

  updateEmployeeEmployment(employeeId: string, employment: Partial<Employee>): Observable<{}> {
    const value = Object({});
    value.employeeTypeId = employment.employeeType;
    value.defaultHourlyWage = employment.defaultHourlyWage;

    return this.httpClient.put(`${ environment.api }/employee/${ employeeId }/employment`, value);
  }

  createCertificate(employeeId: string, certificate: Certificate): Observable<{}> {
    return this.httpClient.post<Certificate>(`${ environment.api }/employee/${ employeeId }/certificate`, certificate);
  }

  editCertificate(employeeId: string, certificate: Certificate): Observable<{}> {
    return this.httpClient.put<Certificate>(`${ environment.api }/employee/${ employeeId }/certificate/${ certificate.id }`,
      certificate);
  }

  deleteCertificate(certificateId: string, employeeId: string): Observable<{}> {
    return this.httpClient.delete(`${ environment.api }/employee/${ employeeId }/certificate/${ certificateId }`);
  }

  editPassport(employeeId: string, passport: Passport): Observable<{}> {
    return this.httpClient.put(`${ environment.api }/employee/${ employeeId }/passport`, passport);
  }

  addVisa(employeeId: string, visa: Visa): Observable<{}> {
    return this.httpClient.post<Visa>(`${ environment.api }/employee/${ employeeId }/visa`, visa);
  }

  editVisa(employeeId: string, visa: Visa): Observable<{}> {
    return this.httpClient.put<Visa>(`${ environment.api }/employee/${ employeeId }/visa/${ visa.id }`, visa);
  }

  deleteVisa(visaId: string, employeeId: string): Observable<{}> {
    return this.httpClient.delete(`${ environment.api }/employee/${ employeeId }/visa/${ visaId }`);
  }

  saveProfessionAndSkills(employeeId: string, skillsAndProfessions: Partial<Employee>): Observable<{}> {
    return this.httpClient.put(
      `${ environment.api }/employee/${ employeeId }/skill`,
      EmployeeService.prepareSkillsAndProfessionsData(skillsAndProfessions),
    );
  }

  removeEmployee(employeeIds: string[]): Observable<{}> {
    return this.httpClient.request('delete', `${ environment.api }/employee`, { body: { employeeIds } });
  }

  uploadFiles(files: File[], employeeId: string, type: string, additionalId: string): Observable<{}> {
    const requests = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);

      if (additionalId === null) {
        return this.httpClient.post<{}>(`${ environment.api }/file/${ employeeId }/${ type }`, formData);
      }
      return this.httpClient.post<{}>(`${ environment.api }/file/${ employeeId }/${ type }/${ additionalId }`,
        formData);
    });

    return forkJoin(requests);
  }

  removeFile(id: string): Observable<{}> {
    return this.httpClient.delete<{}>(`${ environment.api }/file/${ id }`);
  }

  getFile(id: string): Observable<Blob> {
    return this.httpClient.get(`${ environment.api }/file/${ id }`, { responseType: 'blob' });
  }

  getEmployeeTravelFiles(employeeId: string): Observable<EmployeeFile[]> {
    return this.httpClient.get<EmployeeFile[]>(`${ environment.api }/file/${ employeeId }/travel`);
  }

  getEmployeePhotos(employeeId: string): Observable<EmployeeFile[]> {
    return this.httpClient.get<EmployeeFile[]>(`${ environment.api }/file/${ employeeId }/photo`);
  }

  getEmployeeCVs(employeeId: string): Observable<EmployeeFile[]> {
    return this.httpClient.get<EmployeeFile[]>(`${ environment.api }/file/${ employeeId }/cv`);
  }

  showFileByType(employeeId: string, type: string, visaId: string): Observable<EmployeeFile[]> {
    return this.httpClient.get<EmployeeFile[]>(`${ environment.api }/file/${ employeeId }/${ type }/${ visaId }`);
  }

  mapToContract(contractForm: ContractForm, compensationTypes: CompensationType[]): Contract {
    if (contractForm === null) return null;

    const compensationType = compensationTypes.find((compensationType) =>
      compensationType.id === contractForm.contractDetails.compensationType,
    );
    const contractDetails = {
      ...contractForm.contractDetails,
      compensationType,
      projectHull: contractForm.contractDetails.projectHull ? contractForm.contractDetails.projectHull : 'To be settled',
    };

    return {
      ...contractForm,
      contractDetails,
    };
  }

  addContract(employeeId: string, newContract: ContractForm): Observable<{}> {
    return this.httpClient.post(`${ environment.api }/employee/${ employeeId }/contract`, newContract);
  }

  getEmployeesContracts(employeeId: string): Observable<Contracts> {
    if (!this.employeeContractsCache.has(employeeId)) {
      this.employeeContractsCacheRefreshers.set(employeeId, new BehaviorSubject<void>(undefined));

      this.employeeContractsCache.set(
        employeeId,
        this.employeeContractsCacheRefreshers
          .get(employeeId)
          .pipe(
            switchMap(() => zip(
              this.httpClient.get<ContractForm | null>(`${ environment.api }/employee/${ employeeId }/current-contract`),
              this.httpClient.get<ContractForm[]>(`${ environment.api }/employee/${ employeeId }/contract-history`),
              (current, historical) => ({ current, historical }),
            )),
            switchMap((contracts) =>
              this.compensationTypeService.compensationTypes$.pipe(
                map((compensationTypes) => {
                  const mappedCurrentContract = this.mapToContract(contracts.current, compensationTypes);
                  const mappedHistoricalContracts = contracts.historical.map((contractForm) =>
                    this.mapToContract(contractForm, compensationTypes),
                  );

                  return { current: mappedCurrentContract, historical: mappedHistoricalContracts };
                }),
              ),
            ),
            shareReplay(1),
          ),
      );
    }

    return this.employeeContractsCache.get(employeeId);
  }

  refreshContracts(employeeId: string): void {
    this.employeeContractsCacheRefreshers.get(employeeId)?.next();
  }

  editContract(employeeId: string, editedContract: ContractForm): Observable<{}> {
    return this.httpClient.put(`${ environment.api }/employee/${ employeeId }/contract`, editedContract);
  }

  removeContract(employeeId: string, contract: Contract): Observable<{}> {
    return this.httpClient.delete(`${ environment.api }/employee/${ employeeId }/contract/${ contract.id }`);
  }

  addEquipmentToEmployee(
    employeeId: string,
    equipmentId: number,
    issuedOn: string
  ): Observable<{}> {
    return this.httpClient.post(
      `${environment.api}/employee/${employeeId}/equipment`,
      { equipmentId, issuedOn }
    );
  }

  removeEquipmentFromEmployee(
    employeeId: string,
    equipmentRentalId: string
  ): Observable<{}> {
    return this.httpClient.delete(
      `${environment.api}/employee/${employeeId}/equipment/${equipmentRentalId}`
    );
  }

  returnEquipmentForEmployee(
    employeeId: string,
    equipmentRentalId: string,
    returnedOn: string
  ): Observable<{}> {
    return this.httpClient.post(
      `${environment.api}/employee/${employeeId}/equipment/${equipmentRentalId}/return`,
      { returnedOn }
    );
  }

}
