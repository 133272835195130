import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, take, tap } from 'rxjs/operators';
import { RoleCode } from './role';

export interface LoggedInUser {
  roles: RoleCode[];
  username: string;
  firstName?: string;
  lastName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject$ = new BehaviorSubject<LoggedInUser>(JSON.parse(localStorage.getItem('currentUser')));
  readonly user$ = this.userSubject$.asObservable();
  readonly isAdmin$ = this.user$.pipe(
    map((user) => user.roles.some((role) => role === RoleCode.Administrator)),
  );
  readonly isConsultant$ = this.user$.pipe(
    map((user) => user.roles.some((role) => role === RoleCode.Consultant)),
  );

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<LoggedInUser> {
    return this.http.post<LoggedInUser>(
      `${environment.api}/login`,
      { username, password },
      { withCredentials: true }
    ).pipe(
      tap((user) => {
        this.userSubject$.next(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
      }),
    );
  }

  reloadUser(): void {
    this.http.get<LoggedInUser>(`${ environment.api }/status`, { withCredentials: true }).pipe(
      take(1),
      tap((user) => this.userSubject$.next(user)),
    ).subscribe();
  }

  logout(): Observable<{}> {
    return this.http.post<{}>(`${ environment.api }/logout`, {}, { withCredentials: true }).pipe(
      catchError(() => {
        this.userSubject$.next(null);
        return of({});
      }),
      tap(() => this.userSubject$.next(null)),
    );
  }
}
